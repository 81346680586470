import { globals } from 'config'
import { universal } from 'database/db'
import { ASSET_LOCATION, RendererEvent, SHARE_PROTOCOL } from 'electron-shared'
import { CurrentCountryType, CurrentLanguageType } from 'types'
import { dbCurrentEntries } from 'config/constants'
import { AppDataKeyEnum } from 'config/libs/globals'

const base64 = require('base-64')

export const getCurrentCountry = async (): Promise<CurrentCountryType> => {
  let currentCountry = globals.GET_APP_DATA(AppDataKeyEnum.currentCountry) as CurrentCountryType
  if (!currentCountry) {
    const country = await universal.dcaSettings.get(dbCurrentEntries.CURRENT_COUNTRY)
    currentCountry = country?.data ?? {}
  }
  return currentCountry
}

export const getCurrentLanguage = async (): Promise<CurrentLanguageType> => {
  let currentLanguage = globals.GET_APP_DATA(AppDataKeyEnum.currentLanguage) as CurrentLanguageType
  if (!currentLanguage) {
    const language = await universal.dcaSettings.get(dbCurrentEntries.CURRENT_LANGUAGE)
    currentLanguage = language?.data ?? {}
  }
  return currentLanguage
}

export const getAppLang = (activeLanguage: string) => {
  const validLanguages = ['ru', 'el', 'vi', 'uk', 'bg']
  return validLanguages.includes(activeLanguage) ? { lang: 'ru' } : {}
}

export function isObjectEmpty(obj: object | undefined): boolean {
  if (!obj || obj === null || obj === undefined) {
    return true
  }

  if (Object.keys(obj).length === 0) {
    return true
  }

  return false
}

/**
 * Do an image authentication to get the image from the new media server
 * @param imageUrl
 * @returns
 */
export async function imageAuthentication(imageUrl: string): Promise<string> {
  if (
    process.env.REACT_APP_DEPLOYMENT_ENV !== 'development' ||
    !process.env.REACT_APP_NEW_MEDIA_URL
  ) {
    return imageUrl
  }

  if (!imageUrl.includes(process.env.REACT_APP_NEW_MEDIA_URL)) {
    return imageUrl
  }

  const url = new URL(imageUrl)
  const credentials = `${process.env.REACT_APP_MEDIA_USERNAME}:${process.env.REACT_APP_MEDIA_PASSWORD}`
  const authorization = `Basic ${base64.encode(credentials)}`

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: authorization,
      'Accept-Encoding': 'gzip, deflate, br',
    },
    redirect: 'follow',
  }

  const response = await fetch(url.toString(), requestOptions)

  if (!response.ok) {
    return imageUrl
  }

  const blob = await response.blob()

  return URL.createObjectURL(blob)
}

export const splitArrayIntoChunks = <T>(array: T[], chunkSize: number): T[][] => {
  const chunks = []
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize))
  }
  return chunks
}

export const toLocalImgName = (url: string) =>
  url
    .replace(/http:\/\/|https:\/\//g, '') // Remove 'http://' and 'https://'
    .replace(/\//g, '-') // Replace '/' with '-'
    .replace(/\s/g, '') // Remove spaces
    .replace('#webp', '') // Remove '#webp'

export const toLocalImagePath = (url: string) =>
  `${SHARE_PROTOCOL}://${ASSET_LOCATION}/${toLocalImgName(url)}`

export const isLocalImageExist = async (src: string) => {
  if (!window.electron?.isDesktop()) return false
  const path = await window.electron.asyncInvoke(RendererEvent.IS_FILE_EXIST, {
    fileName: toLocalImgName(src),
    fileLocation: ASSET_LOCATION,
  })
  return path
}

export const loadImageForPDF = async (url: string): Promise<string> => {
  const isExist = await isLocalImageExist(url)
  if (!isExist) return url

  return toLocalImagePath(url)
}

export const convertBlobToBase64 = (blob: Blob) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result)
      } else {
        reject(new Error('Failed to convert blob to base64'))
      }
    }
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })

export const showAlert = (message: string) => {
  if (window.electron?.isDesktop()) {
    window.electron.invoke(RendererEvent.SHOW_ALERT, { message })
  } else {
    alert(message)
  }
}

export function getContentRibbon(content: string) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(content, 'text/html')
  const pElements = doc.querySelectorAll('p')
  if (pElements.length === 0) {
    return {
      subHeadline: '',
      description: content,
    }
  }
  if (pElements.length === 1) {
    return {
      subHeadline: '',
      description: pElements[0].textContent ?? '',
    }
  }
  return {
    subHeadline: pElements[0].textContent ?? '',
    description: pElements[1].textContent ?? '',
  }
}
