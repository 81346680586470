/* eslint-disable camelcase */
export const TRANSLATIONS = {
  settings: 'Setting',
  search: 'Search',
  productComparison: 'Product Comparison',
  favorites: 'Favorites',
  productFinder: 'Product Finder',
  cookiePolicy: 'Cookie Policy',
  cookieDefinitionHeadline: 'What are Cookies?',
  cookieDefinition:
    'A Cookie is a small piece of data (text file) that a website - when visited by a user - asks your browser to store on your device in order to remember information about you, such as your language preference or login information. Those cookies are set by us and called first-party cookies. We also use third-party cookies - which are cookies from a domain different than the domain of the website you are visiting - for our advertising and marketing efforts.',
  cookieSettingsHeadline: 'Cookie Settings',
  cookieSettingsDefinition: 'Here you can change your cookie settings for this website.',
  download: 'Download',
  lastDownload: 'Last download',
  downloadStatus: 'Download Status',
  settingsDownloadCaption: 'Simply select which areas you would like to download.',
  backToPreViousPage: 'Back to previous page',
  doYouWantToChangeSettings:
    'All the offline data except Favorites, Coloring and Marking will be removed.',
  countryAndLanguageSettings: 'Country and Language settings',
  change: 'Change',
  changeTheCountry: 'Change the country',
  changeTheLanguage: 'Change the language',
  nothingIsSelectedForDownload: 'Select anyone of the options',
  youAreOffline: 'You are offline. Connect to internet to download.',
  notPartCurrentProductRange: 'Not part of our current product range',
  noProductAreSelectedForComparison: 'No products are selected for comparison',
  warning: 'Warning',
  export: 'Export',
  exportShort: 'Export Short',
  orderNumber: 'Order Number',
  price: 'Price',
  remove: 'Remove',
  technicalData: 'Technical data',
  equipments: 'Equipments',
  description: 'Description',
  comparisonTitle: 'THE FOLLOWING ITEM WAS ADDED TO COMPARISON',
  myFavorites: 'My Favorites',
  sortByName: 'Sort by name',
  createNewFolder: 'Create new folder',
  exportFavoriteAsText: 'Export favorite as text',
  importFavorites: 'Import favorites',
  addToFavorites: 'Add to favorites',
  edit: 'Edit',
  copy: 'Copy',
  delete: 'Delete',
  compare: 'Compare',
  all: 'All',
  import: 'Import',
  finished: 'Finished',
  onboarding_download_description: ''
}
