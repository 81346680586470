import { StructureItemType } from 'types'
import { loadImageForPDF } from 'config/utils/CommonFunction'

export const getBreadcrumbData = (pageId: number, structure: StructureItemType[] | null) => {
  if (!structure) return []
  const result = []

  let currentRouteId: number | null | undefined = pageId
  while (currentRouteId) {
    const route = structure.find((item) => item.id.toString() === currentRouteId?.toString())
    if (route) {
      result.push(route)
    }
    currentRouteId = route?.parentId
  }
  return result.reverse()
}

export const formatAnchorTag = (str: string) => {
  if (!str.includes('this.globalAppNavigation')) return str
  // remove href="#" onClick={() => {this.globalAppNavigation('pageId')}}
  return str.replace(/href="#" onClick=\{.*?\}\}/g, '')
}

const loadLocalImages = async (htmlDocument: Document) => {
  const images = htmlDocument.getElementsByTagName('img')
  const imagePromises = Array.from(images).map(async (img) => {
    try {
      const newImageUrl = await loadImageForPDF(img.src)
      img.src = newImageUrl
    } catch (error) {
      console.error(error)
    }
  })
  await Promise.all(imagePromises)
}

const createIframe = () => {
  const iframe = document.createElement('iframe')
  iframe.style.width = '1px'
  iframe.style.height = '1px'
  return iframe
}

// used for debug PDF HTML
const openNewPDFTab = (htmlDocument: Document) => {
  const newWin = window.open('', '_blank')
  newWin?.document.write(htmlDocument.documentElement.outerHTML)
}

export const printDocument = async (htmlDocument: Document, onDone?: () => void) => {
  try {
    const iframe = createIframe()
    document.body.appendChild(iframe)

    // Handle load images for PDF from local
    if (window.electron?.isDesktop()) {
      loadLocalImages(htmlDocument)
    }

    // write content
    const content = iframe.contentDocument!
    content.open()
    content.write(htmlDocument.documentElement.outerHTML)
    content.close()

    const print = () => {
      document.title = content.title
      setTimeout(() => {
        iframe.contentWindow?.print()
        if (onDone) {
          onDone()
        }
      }, 300)

      // clean up
      setTimeout(() => {
        document.body.removeChild(iframe)
        document.title = 'Kärcher Programme'
      }, 3000)
    }

    // timeout for onload function 20s
    const timeout = setTimeout(() => {
      iframe.onload = function () {}
      print()
    }, 20 * 1000)

    iframe.onload = function () {
      clearTimeout(timeout)
      print()
    }
  } catch (error) {
    console.log('error printing document', error)
    if (onDone) {
      onDone()
    }
  }
  // openNewPDFTab(htmlDocument)
}

export const tr = (translations: { [key: string]: string }, key: string, fallback: string = '') =>
  translations[key] || fallback
