import { imageAuthentication } from 'config/utils/CommonFunction'
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useSelector } from 'react-redux'
import { selectTranslations } from 'redux/selector'
import 'styles/pages/businessUnitEntry.scss'
import { PageContentResponseType } from 'types/pageContentType'

interface LayoutDataType {
  values: {
    button_link_left: number
    button_text_left: string
    headline_left: string
    image_left: string
    button_link_right: number
    button_text_right: string
    headline_right: string
    image_right: string
    home_garden: string
    professional: string
  }[]
}

interface Props {
  data: PageContentResponseType | object
  onNavigation: (pageId: number, headline: string, division: string) => void
}

const BusinessUnitEntryUI: React.FC<Props> = ({ data, onNavigation }) => {
  const translations = useSelector(selectTranslations)

  const [imageAuthLeft, setImageAuthLeft] = useState('')
  const [imageAuthRight, setImageAuthRight] = useState('')

  useEffect(() => {
    async function getImageAuthentication(layoutData: LayoutDataType) {
      const authLeft = await imageAuthentication(layoutData.values[0].image_left ?? '')
      setImageAuthLeft(authLeft)
      const authRight = await imageAuthentication(layoutData.values[0].image_right ?? '')
      setImageAuthRight(authRight)
    }

    if ('content' in data) {
      const layoutData: LayoutDataType = JSON.parse(data.content[0].config)
      getImageAuthentication(layoutData)
    }
  }, [data])

  if (!('content' in data) || !translations) return null

  const layoutData: LayoutDataType = JSON.parse(data.content[0].config)

  const renderCatalogue = (
    link: number,
    buttonText: string,
    headline: string,
    division: 'first' | 'second',
    image?: string,
  ) => {
    let entityTitle: string
    if (headline) {
      entityTitle = headline
    } else {
      entityTitle = division === 'first' ? translations.home_garden : translations.professional
    }

    return (
      <Col
        id={`section-${division}`}
        className={`section-${division} sec-click`}
        onClick={() => onNavigation(link, headline, division)}
      >
        {image && (
          <LazyLoadImage
            id={division}
            className='catalog-img'
            alt='business-image'
            src={image}
            effect='blur'
            threshold={500}
          />
        )}

        <h1>{entityTitle}</h1>

        <Button
          className={`${division === 'first' ? 'btn-dark ' : 'btn-light'} theme-btn my-3`}
          style={{ width: '200px' }}
          onClick={() => onNavigation(link, headline, division)}
        >
          {buttonText}
        </Button>
      </Col>
    )
  }

  return (
    <Container className='catalogue-container' fluid>
      <Row className='catalogue-row'>
        {renderCatalogue(
          layoutData.values[0].button_link_left,
          layoutData.values[0].button_text_left,
          layoutData.values[0].headline_left,
          'first',
          imageAuthLeft,
        )}
        {renderCatalogue(
          layoutData.values[0].button_link_right,
          layoutData.values[0].button_text_right,
          layoutData.values[0].headline_right,
          'second',
          imageAuthRight,
        )}
      </Row>
    </Container>
  )
}

export default BusinessUnitEntryUI
