import { PageContentResponseType, ProductDetailItemType, StructureItemType } from 'types'
import { getBreadcrumbData, printDocument } from '../pdfHelper'
import { getCustomHTML } from '../pdfLayouts'
import { pageBreak } from '../styles'
import { RibbonData, TranslationsPDFType } from '../types'
import getApplications from './getApplications'
import getCleaningAgents from './getCleaningAgents'
import getConfigurableComponents from './getConfigurableComponents'
import getDetergentWarnings from './getDetergentWarnings'
import getEquipment from './getEquipment'
import getFeaturesAndBenefits from './getFeaturesAndBenefits'
import getIcons from './getIcons'
import getProductInfo from './getProductInfo'

export const toProductDetailHTML = (
  productData: ProductDetailItemType,
  translations: TranslationsPDFType,
) => {
  let htmlStr = ''
  htmlStr += getProductInfo(productData, translations)
  htmlStr += getEquipment(productData.equipment, translations)
  htmlStr += getIcons(productData.icons)
  htmlStr += getFeaturesAndBenefits(productData.featureBenefits, translations)
  htmlStr += getApplications(productData.applications, translations)
  htmlStr += getDetergentWarnings(productData.detergentWarnings, translations)
  htmlStr += getConfigurableComponents(productData?.configurableComponent ?? [], translations)
  htmlStr += getCleaningAgents(productData, translations)
  return htmlStr
}

export const createProductDetailPDF = (
  data: {
    pageContent: PageContentResponseType
    structure: StructureItemType[] | null
    productData: ProductDetailItemType
    translations: TranslationsPDFType
    ribbonData: RibbonData
  },
  onDone?: () => void,
) => {
  const { pageContent, structure, productData, translations, ribbonData } = data

  const breadCrumbData = getBreadcrumbData(pageContent.pageId, structure)
  const productHTML = toProductDetailHTML(productData, translations)

  const htmlDOM = getCustomHTML({
    ribbonData,
    breadCrumbData,
    pageStyles: productDetailStyle,
    title: ribbonData?.headline,
  })

  const contentHTML = htmlDOM.getElementById('main')!
  contentHTML.innerHTML += productHTML
  printDocument(htmlDOM, onDone)
}

export const productDetailStyle = `
 .ribbon-text {
      margin-top: 20px;
      padding: 20px;
      background-color: #FFED00;
    }
    .ribbon-text h1 {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .section1 {
      padding-top: 30px;
      ${pageBreak}
    }
    .equipment-sec {
      padding-top: 30px;
      ${pageBreak}
    }
    .icon-sec {
      padding-top: 30px;
      ${pageBreak}
    }
    .table {
      display: table;
      border-spacing: 0px;
      border-color: white;
      border-collapse: collapse;
    }
    .table tr:nth-child(odd) {
      background-color: #e3e3e3;
    }
    .table td {
      padding: .5rem;
    }
    .image-small {
      width: 75px;
      height: 75px;
      padding: .5em;
    }
    .detail-card {
      background: #e3e3e3;
      padding: 0px 10px;
    }
    .feature-benefit-sec-1 {
      padding-top: 30px;
      ${pageBreak}
    }
    .feature-benefit-sec-2 {
      display: grid;
      padding-top: 10px;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      ${pageBreak}
    }
    .compatible-machines-sec {
      padding-top: 30px;
      ${pageBreak}
    }
    .compatible-machines-current {
      padding-top: 30px;
      ${pageBreak}
    }
    .compatible-machines-past {
      padding-top: 30px;
      ${pageBreak}
    }
    .compatible-machines-sec ul {
      margin: 0
    }
    .applications-sec {
      padding-top: 30px;
      ${pageBreak}
    }
    .detergent-warnings-sec {
      padding-top: 30px;
      ${pageBreak}
    }
    .configurable-component-sec {
      padding-top: 30px;
      ${pageBreak}
    }
    .cleaning_agents_and_accessories_sec {
      padding-top: 30px;
      ${pageBreak}
    }
    .technical-data-sec {
      padding-top: 30px;
      ${pageBreak}
    }
  `
