export interface Image {
  title: string
  type: ImageType
  urls: URL[]
}

export enum ImageType {
  Application = 'application',
  Detail = 'detail',
  Extra = 'extra',
  MainProduct = 'mainproduct',
  WebPlus = 'webplus',
}

export interface URL {
  type: URLType
  url: string
}

export enum URLType {
  Full = 'full',
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
  Thumb = 'thumb',
}

export enum ProductType {
  Accessory = 'accessory',
  Machine = 'machine',
  PastProduct = 'pastproduct',
}

export interface Text {
  value: string
  type: TextType
}

export enum TextType {
  Long = 'long',
  Short = 'short',
}

export type RootlineUpdated = {
  id: number
  title: string
}

export interface SearchItem {
  id: number
  name: string
  partnumber: string
  partnumberFormatted: string
  productType: ProductType
  images: Image[]
  texts?: Text[]
  price?: string
  priceFormatted?: string
  priceGross?: string
  priceGrossFormatted?: string
  priceNet?: string
  priceNetFormatted?: string
  productgroupId?: number
  rootline_updated?: string
}

export type SearchContent = {
  headline: string
  text?: string
  pageId: number
}

export enum SearchQueryDataKey {
  hg = 'hg',
  pro = 'pro',
  accessories = 'accessories',
  detergents = 'detergents',
  old = 'old',
  content = 'content',
}

export type ProductGroupCategory = {
  id: number
  title: string
}

export interface Bucket {
  docCount: number
  items: SearchItem[] | SearchContent[]
  pgCategories?: ProductGroupCategory[]
}

export type SearchResponseType = {
  [key: string]: Bucket
  accessories: Bucket
  detergents: Bucket
  content: Bucket
  hg: Bucket
  pro: Bucket
}

export type SearchSuggestionResponseType = SearchItem[]
